import {Platform} from '@angular/cdk/platform';
import {Injectable} from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {Observable, timer} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PWAService {
    deferredPrompt: any;
    isIOS: any;

    constructor(private platform : Platform, private bottomSheet : MatBottomSheet,) {
        this.isIOS  = this.platform.IOS;
    }

    public listen(): void {
        window.addEventListener('beforeinstallprompt', (event) => { // Prevent Chrome 67 and earlier from automatically showing the prompt
            event.preventDefault();
            // Stash the event so it can be triggered later.
            this.deferredPrompt = event;
        });
    }

    public triggerInstall(): Observable < boolean > {
        return new Observable(observe => { // Show the prompt
            this.deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    observe.next(true);
                    observe.complete();
                } else {
                    observe.next(false);
                    observe.complete();
                }
                this.deferredPrompt = null;
            });
        });
    }

    // Método para verificar si el PWA está instalado en Android
    public isPWAInstalledOnAndroid(): boolean {
        // Se puede verificar si el PWA está instalado comparando si el servicio
        // Worker está disponible y si el usuario está en un dispositivo Android
        return ('serviceWorker' in navigator) && ('platform' in navigator && /android/i.test(navigator.platform));
    }
}
