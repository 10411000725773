import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-simple-question",
  templateUrl: "./simpleQuestion.component.html",
  styleUrls: ["./simpleQuestion.component.scss"],
})
export class SimpleQuestionComponent implements OnInit{
  @Input() titulo: string;
  @Input() descripcion: string;
  @Input() opciones: string[];
  @Input() correctAnswer: string; // Agregar una variable para la respuesta correcta
  @Input() isRespondida: boolean;
  @Output() sendRespuesta: EventEmitter<any> = new EventEmitter<any>();

  singleChoiceForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.singleChoiceForm = this.fb.group({
      selectedOption: ['']
    });
    console.log("dsadsa")
    console.log(this.isRespondida)
    if(this.isRespondida){
      this.singleChoiceForm = this.fb.group({
        selectedOption: [{ value: this.correctAnswer, disabled: true }]
      });
    }
  }

  onSubmit() {
    const selectedOption = this.singleChoiceForm.value.selectedOption;
    if (selectedOption === this.correctAnswer) {
      this.sendRespuesta.emit(true);
    } else {
      this.sendRespuesta.emit(false);
    }
  }
}
