import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RutModule } from "rut-chileno";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { RouterModule } from "@angular/router";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/login/login.component";
import { ToolbarComponent } from "./components/toolbar/toolbar.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularMaterialModule } from "./angular-material.module";
import { BienvenidaComponent } from "./components/bienvenida/bienvenida.component";
import { HttpClientModule } from "@angular/common/http";
import { CursoComponent } from "./components/curso/curso.component";
import { Modulo1Component } from "./components/modulos/modulo1/modulo1.component";
import { Modulo2Component } from "./components/modulos/modulo2/modulo2.component";
import { Modulo3Component } from "./components/modulos/modulo3/modulo3.component";
import { Modulo3JardinesComponent } from "./components/modulos/modulo3jardines/modulo3-jardines.component";
import { Modulo4Component } from "./components/modulos/modulo4/modulo4.component";
import { ModuloAutoInstruccionComponent } from "./components/modulos/modulo-auto-instruccion/modulo-auto-instruccion.component";
import { PaginacionComponent } from "./components/modulos/shared/paginacion/paginacion.component";
import { OrderQuestionComponent } from "./components/orderQuestion/orderQuestion.component";
import { SimpleQuestionComponent } from "./components/simpleQuestion/simpleQuestion.component";
import { AlternativeQuestionComponent } from "./components/alternativeQuestion/alternativeQuestion.component";
import { TrueFalseQuestionComponent } from "./components/trueFalseQuestion/trueFalseQuestion.component"
import { CustomerQuestionComponent } from "./components/customerQuestion/customerQuestion.component";
import { ImageZoomDialogComponent } from "./components/imageZoomDialog/image-zoom-dialog.component";
import { PersonalizadoQuestionComponent } from "./components/personalizadoQuestion/personalizadoQuestion.component";

import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ReactiveFormsModule } from "@angular/forms";
import {MatSelectModule} from '@angular/material/select';


import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatButtonModule } from "@angular/material/button";

/* Firebase */
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireModule } from "@angular/fire";

import { PdfViewerModule } from "ng2-pdf-viewer";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ToolbarComponent,
    BienvenidaComponent,
    CursoComponent,
    Modulo1Component,
    Modulo2Component,
    Modulo3Component,
    Modulo3JardinesComponent,
    Modulo4Component,
    ModuloAutoInstruccionComponent,
    PaginacionComponent,
    OrderQuestionComponent,
    SimpleQuestionComponent,
    AlternativeQuestionComponent,
    TrueFalseQuestionComponent,
    CustomerQuestionComponent,
    ImageZoomDialogComponent,
    PersonalizadoQuestionComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    RouterModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    RutModule,
    MatDialogModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    AngularFirestoreModule,
    PdfViewerModule,
    MatProgressSpinnerModule,
    DragDropModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatSelectModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
