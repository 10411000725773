<div class="container-pregunta">
  <div class="block-preguntas flex flex-col items-center pt-1 bg-white rounded-lg shadow-lg w-full text-xs">
    <h1 class="text-md font-bold py-1">{{ titulo }}</h1>
    <h3 class="text-center text-sm p-2">
      {{ descripcion }}
    </h3>
    <form  class="pr-3"[formGroup]="orderForm" (ngSubmit)="onSubmit()" >
      <div
        cdkDropList
        #list="cdkDropList"
        [cdkDropListData]="opciones"
        [cdkDropListConnectedTo]="[list]"
        class="list-group p-3 px-5 text-xs"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          *ngFor="let option of opciones; let i = index"
          cdkDrag
          class="list-group-item text-xs"
        >
          <span>{{ option }}</span>
        </div>
      </div>
      <div class="w-100 px-4 py-6">
        <button
            [disabled]="isRespondida"
            type="submit"
          class="btn bg-orange-500 btn-lg w-100 p-2 text-white text-lg font-bold"
        >
          Verificar
        </button>
      </div>
    </form>
  </div>
</div>
