<mat-toolbar color="primary" class="toolbar sticky-top">
  <button mat-icon-button (click)='toCurso()'>
    <mat-icon>home</mat-icon>
  </button>
  <div class="hidden sm:grid grid-cols-2 py-2 ">
    <div class="flex justify-end pr-10 items-center"> <!-- Para alinear a la derecha -->
      <img src="assets/icons/icon-128x128.png" style="max-height: 60px; max-width: 200px;"/>
    </div>
    <div class="flex justify-start items-center"> <!-- Para alinear a la izquierda -->
      <img src="assets/images/newAlicopsa.png" style="max-height: 50px; max-width: 200px;" />
    </div>
  </div>
  
  <div class="items-center item-center flex">
    <div class="px-3 pt-2">
      <mat-icon *ngIf='!isIOS ' class="text-white" (click)="installAndroid()" >arrow_downward</mat-icon>
    </div>

    <div class="px-3 pt-2">
      <mat-icon *ngIf='!isConnect()' class="iconRed">sync_disabled</mat-icon>
      <mat-icon *ngIf='isConnect()' class="iconGreen">sync</mat-icon>
    </div>
    
    <button
        class="btn bg-orange-600 text-white"
        (click)="cerrarSesion()"
      >
        CERRAR SESIÓN
      </button>
  </div>
</mat-toolbar>
