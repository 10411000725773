<app-toolbar></app-toolbar>
<div class="container">
  <div class="pdf-container" *ngIf="isPaginaPdf()">
    <pdf-viewer
      [src]="pdfModulo4"
      [render-text]="true"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="onPageRendered($event)"
      [show-all]="false"
      [page]="page"
      [original-size]="false"
    ></pdf-viewer>
  </div>

  <div class="img-tabla"  *ngIf="isImagenActiva(0)">
    <img class="w-full h-full" [src]="img1">
  </div>

  <div class="img-tabla"  *ngIf="isImagenActiva(1)">
    <img class="w-full h-full" [src]="img2">
  </div>

  <div class="img-tabla "  *ngIf="isImagenActiva(2)">
    <img class="w-full h-full" [src]="img3">
  </div>


  <div *ngIf="isPreguntaActiva()">

    <div *ngIf="validShowPregunta(1)">
      <app-simple-question
      [titulo]="titulo1"
        [descripcion]="descripcion1"
        [opciones]="opciones1"
        [correctAnswer]="correcta1"
        [isRespondida]="validRespondida(1)"
        (sendRespuesta)="validarQuestion($event,1)"
      ></app-simple-question>
    </div>

    <div *ngIf="validShowPregunta(2)">
      <app-simple-question
      [titulo]="titulo2"
        [descripcion]="descripcion2"
        [opciones]="opciones2"
        [correctAnswer]="correcta2"
        [isRespondida]="validRespondida(2)"
        (sendRespuesta)="validarQuestion($event,2)"
      ></app-simple-question>
    </div>

    <div *ngIf="validShowPregunta(3)">
      <app-simple-question
      [titulo]="titulo3"
        [descripcion]="descripcion3"
        [opciones]="opciones3"
        [correctAnswer]="correcta3"
        [isRespondida]="validRespondida(3)"
        (sendRespuesta)="validarQuestion($event,3)"
      ></app-simple-question>
    </div>

    <div *ngIf="validShowPregunta(4)">
      <app-true-false-question
        [titulo]="titulo4"
        [descripcion]="descripcion4"
        [questions]="preguntas4"
        [isRespondida]="validRespondida(4)"
        (sendRespuesta)="validarQuestion($event,4)"
      ></app-true-false-question>
    </div>

    <div class="p-4 justify-center item-center" *ngIf="validShowPregunta(5)">
      <h1>🎉 ¡FELICIDADES HAZ FINALIZADO EL MODULO! 🎉</h1>
      <img class="w-full p-4" src="../../../../assets/images/fin-modulo.jpg" />
      <div class="w-100 px-4 py-6">
        <button
          class="btn bg-orange-500 btn-lg w-100 p-2 text-white text-lg font-bold"
          (click)="finModulo()"
        >
          Salir
        </button>
      </div>
    </div>
  </div>
</div>
<app-paginacion
  [page]="page"
  [totalPages]="totalPages"
  [cantPreg]="cantPreg"
  (prevPage)="prevPage()"
  (nextPage)="nextPage()"
></app-paginacion>
