<app-toolbar></app-toolbar>
<div
  class=" container-curso justify-content-center align-items-center fondoPrincipal"
>
  <br />
  <div
    class="bg-gray-200 bg-opacity-75 max-w-90 mx-auto py-3 rounded-lg font-bold p-8 px-10"
  >
    <h4 class="text-center">PRESENTACIÓN DEL CURSO</h4>
    <h1 class="text-center">Buenas Prácticas de Manufactura PAE - PAP</h1>
  </div>
  <div class="container p-1">
    <h2 class="text-center">Programación del curso:</h2>
    <mat-accordion class="accordion-curso">
      <!-- caso exitoso -->
      <mat-expansion-panel
        *ngIf="isComple(0)"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        class="m-2 colorGreen"
        id="section0"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> 0. Instrucciones app </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list [multiple]="false">
          <mat-list-item *ngFor="let pagina of introduccion; let i = index">
            <mat-icon>play_circle_outline</mat-icon>
            <a
              matLine
              href="/modulo/MANUAL-DE-AUTO-INSTRUCCION#pagina{{ i + 1 }}"
              >0.{{ i + 1 }} - {{ pagina }}</a
            >
            {{ setPaginasModulo0(i + 1) }}
          </mat-list-item>
        </mat-selection-list>
      </mat-expansion-panel>
      <!-- caso aun incompleto-->
      <mat-expansion-panel
        *ngIf="!isComple(0)"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        class="m-2"
        id="section0"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> 0. Instrucciones app </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list [multiple]="false">
          <mat-list-item *ngFor="let pagina of introduccion; let i = index">
            <mat-icon>play_circle_outline</mat-icon>
            <a
              matLine
              href="/modulo/MANUAL-DE-AUTO-INSTRUCCION#pagina{{ i + 1 }}"
              >0.{{ i + 1 }} - {{ pagina }}</a
            >
            {{ setPaginasModulo0(i + 1) }}
          </mat-list-item>
        </mat-selection-list>
      </mat-expansion-panel>
      {{ saveLocaleModulo0() }}
      <!-- INICIO DE SECCION 1 -->
      <BR />
      <h3 class="text-center">Curso</h3>
      <BR />
      <!-- INICIO MODULO 1 -->
      <!-- caso exitoso -->
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        class="m-2"
        [class.colorGreen]="isComple(1)"
        [disabled]="!isHabilitado(1)"
        id="section1"
      >
        <mat-expansion-panel-header class="h-full py-1">
          <mat-panel-title>
            <div class="text-sm">
              1. MARCO LEGAL DEL SISTEMA DE GESTIÓN DE LA CALIDAD (SGC) Y
              SEGURIDAD ALIMENTARIA DEL PROGRAMA ALIMENTACIÓN ESCOLAR JUNAEB
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list [multiple]="false">
          <mat-list-item *ngFor="let pagina of modulo1; let i = index">
            <a
              matLine
              href="/modulo/Normas-Conceptos#{{ i + 1 }}"
              [disabled]="!isValidPagina(i + 1)"
            >
              1.{{ i + 1 }} - {{ pagina }}
            </a>
            <mat-icon>play_circle_outline</mat-icon>
            {{ setPaginasModulo1(i + 1) }}
          </mat-list-item>
        </mat-selection-list>
      </mat-expansion-panel>
      <!-- FIN MODULO 1 -->
      <!-- INICIO MODULO 2 -->
      <!-- caso exitoso -->
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        class="m-2"
        [class.colorGreen]="isComple(2)"
        [disabled]="!isHabilitado(2)"
        id="section2"
      >
        <mat-expansion-panel-header class="h-full py-1">
          <mat-panel-title>
            2. PELIGROS QUE AFECTAN LA SEGURIDAD EN LOS ALIMENTOS
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list [multiple]="false">
          <mat-list-item *ngFor="let pagina of modulo2; let i = index">
            <a
              matLine
              href="/modulo/Peligros#{{ i + 1 }}"
              [disabled]="!isValidPagina(i + 1)"
              >2.{{ i + 1 }} - {{ pagina }}
            </a>
            <mat-icon>play_circle_outline</mat-icon>
            {{ setPaginasModulo2(i + 1) }}
          </mat-list-item>
        </mat-selection-list>
      </mat-expansion-panel>
      {{ saveLocaleModulo2() }}
      <!-- FIN MODULO 2 -->

      <!-- INICIO MODULO 3 -->
      <!-- caso exitoso -->
      <mat-expansion-panel
        *ngIf="isJunaeb"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        class="m-2"
        [class.colorGreen]="isComple(3)"
        [disabled]="!isHabilitado(3)"
        id="section3"
      >
        <mat-expansion-panel-header class="h-full py-1">
          <mat-panel-title>
            3. PROCEDIMIENTOS OPERACIONALES  ESTANDARIZADOS DE SANITIZACIÓN (POES)
            <br>
            PROCEDIMIENTOS  OPERACIONALES ESTANDARIZADOS  (POE)
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list [multiple]="false">
          <mat-list-item *ngFor="let pagina of modulo3; let i = index">
            <a
              matLine
              href="/modulo/BPM#{{ i + 1 }}"
              [disabled]="!isValidPagina(i + 1)"
              >3.{{ i + 1 }} - {{ pagina }}
            </a>
            <mat-icon>play_circle_outline</mat-icon>
            {{ setPaginasModulo3(i + 1) }}
          </mat-list-item>
        </mat-selection-list>
      </mat-expansion-panel>
      <!-- FIN MODULO 3 -->

      <!-- INICIO MODULO 3 JARDINES -->
      <!-- caso exitoso -->
      <mat-expansion-panel

        *ngIf="isJardines"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        class="m-2"
        [class.colorGreen]="isComple(3)"
        [disabled]="!isHabilitado(3)"
        id="section3"
      >
        <mat-expansion-panel-header class="h-full py-1">
          <mat-panel-title>
            3. PROCEDIMIENTOS OPERACIONALES  ESTANDARIZADOS DE SANITIZACIÓN (POES) JARDINES
            <br>
            PROCEDIMIENTOS  OPERACIONALES ESTANDARIZADOS  (POE) JARDINES
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list [multiple]="false">
          <mat-list-item *ngFor="let pagina of modulo3Jardines; let i = index">
            <a
              matLine
              href="/modulo/BPMJARDINES#{{ i + 1 }}"
              [disabled]="!isValidPagina(i + 1)"
              >3.{{ i + 1 }} - {{ pagina }}
            </a>
            <mat-icon>play_circle_outline</mat-icon>
            {{ setPaginasModulo3(i + 1) }}
          </mat-list-item>
        </mat-selection-list>
      </mat-expansion-panel>
      <!-- FIN MODULO 3 JARDINES -->

      <!-- INICIO MODULO 4 -->
      <!-- caso exitoso -->
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        class="m-2"
        [class.colorGreen]="isComple(4)"
        [disabled]="!isHabilitado(4)"
        id="section3"
      >
        <mat-expansion-panel-header class="h-full py-1">
          <mat-panel-title>
            4. TÉCNICAS CULINARIAS
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list [multiple]="false">
          <mat-list-item *ngFor="let pagina of modulo4; let i = index">
            <a
              matLine
              href="/modulo/Tecnicas#{{ i + 1 }}"
              [disabled]="!isValidPagina(i + 1)"
              >4.{{ i + 1 }} - {{ pagina }}
            </a>
            <mat-icon>play_circle_outline</mat-icon>
            {{ setPaginasModulo3(i + 1) }}
          </mat-list-item>
        </mat-selection-list>
      </mat-expansion-panel>
      <!-- FIN MODULO 4 -->
    </mat-accordion>
    <br />
  </div>

  <div class="container p-2" *ngIf="validCursoCompleto()">
    <div
      class="container bg-card shadow rounded-2xl p-3 bg-orange-600 text-center mx-auto text-xl"
    >
      <div class="text-center">Código Término Curso</div>
      <div>
        <button mat-raised-button color="primary" (click)="openDialog()">
            Ver Codigo
          </button>
      </div>
    </div>
  </div>

  <div
    class="bg-gray-200 bg-opacity-75 max-w-90 mx-auto py-3 rounded-lg font-bold p-8 px-10 mb-3"
  >
    <div class="text-center">Rut: {{ rutPersona }}</div>
      <div class="text-center">Nombre: {{ nombrePersona }}</div>
  </div>

  <div
    *ngIf="validAdmin()"
    class="container p-4 colorGris d-flex flex-column justify-content-center align-items-center"
  >
    <h2 class="text-center">Supervisor</h2>
    <hr />
    <button mat-raised-button color="primary" (click)="export()">
      Exportar
    </button>
    <hr />
    <h9 class="text-center"
      >Al momento de exportar, recibirás un archivo excel indicando el módulo y
      la página en la que se encuentra cada alumno que ha iniciado sesión al
      menos una vez en el curso de manera online
    </h9>
  </div>
  <br />
</div>
