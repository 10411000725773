<!-- single-choice-question.component.html -->
<div class="container-pregunta">
  <div class="block-preguntas flex flex-col items-center pt-1 bg-white rounded-lg shadow-lg w-full">
    <h1 class="text-md font-bold p-1" *ngIf="titulo != ''">{{ titulo }}</h1>
    <h3 class="text-sm text-justify text-sm px-2" [innerHTML]="descripcion"></h3>
    <form [formGroup]="questionsForm" (ngSubmit)="onSubmit()">
      <div class="w-full p-3">
        <div *ngFor="let question of questions; let i = index" class="flex flex-col mb-4">
          <div class="grid grid-preguntas">
            <div>
              <label class="text-xs font-bold">{{ question.text }}</label>
            </div>
            <div>
              <label class="option-label mr-4">
                <input [disabled]="isRespondida" type="radio" [checked]="question.respuesta == true" (click)="moveSelect(true,question,i)"/> 
                <mat-icon class="icon iconGreen">check_circle</mat-icon>
              </label>
              <label class="option-label">
                <input [disabled]="isRespondida" type="radio" [checked]="question.respuesta == false" (click)="moveSelect(false,question,i)"/>  
                <mat-icon class="icon iconRed">cancel</mat-icon>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 px-4 py-6">
        <button
          type="submit"
          [disabled]="isRespondida"
          class="btn bg-orange-500 btn-lg w-100 p-2 text-white text-xl font-bold"
        >
          Verificar
        </button>
      </div>
    </form>
  </div>
</div>
