import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RutService } from 'rut-chileno';
import {  validate, clean, format, getCheckDigit } from 'rut.js'
import { LoginService } from 'src/app/services/login/login.service';
import { TrackingService } from 'src/app/services/tracking/tracking.service';
import Swal from 'sweetalert2';


@Component({ selector: 'app-login', templateUrl: './login.component.html', styleUrls: ['./login.component.scss'] })
export class LoginComponent implements OnInit {
  title = 'use-rut';
  rutClean: any;
  isLoading = false;

  constructor(
    private router: Router,
    private rutService: RutService,
    private loginService: LoginService,
    private trackingService: TrackingService,
  ) { }


  ngOnInit(): void { }

  async submit(event: any, rut: string) {
    this.isLoading = true;
    this.limpiarCache();
    event.preventDefault();
    console.log(rut);
    const cleanRut = clean(rut).toLocaleLowerCase();
    console.log(cleanRut)
    if(!validate(cleanRut)){
      Swal.fire({
        icon: 'error',
        title: 'Rut no Valido',
        text: 'Favor ingresar un rut valido'
      });
      this.isLoading = false;
    } else {
      let validacionUsuario = await  this.loginService.validateRutFireBase(cleanRut);
      console.log("espere la infor y valide: "+ validacionUsuario);
      console.log(validacionUsuario);
      if (validacionUsuario.error) {
        Swal.fire({
          icon: 'warning',
          title: validacionUsuario.titulo,
          html: validacionUsuario.msj,
          confirmButtonText: `ok`,
        });
        this.isLoading = false;
        return;
      } else {
        console.log("entreeee");
        this.trackingService.getdbuser(cleanRut);
        console.log("hice el insert nque no debia con : " + cleanRut);
        //this.limpiarRespuestas();
        this.router.navigate(['/bienvenida']);
      }
    }
  }

  onHelp(): void { // Agregar enlace para persona de soporte WSP
    Swal.fire('Ayuda solicitada mediante WSP!');
    const whastappLink = 'https://wa.me/56964908331';
    window.open(whastappLink, '_blank');
  }

  limpiarCache(){
    localStorage.clear();
  }

  getRut(rut: string): void {
    // Recibe 2 variables el rut como string.
    // "mode" el cual corresponde a la misma definicion anterior
    // - mode = 0 -> 184215551
    // - mode = 1 -> 18421555
    // - mode = 2 -> 18.421.555-1
    // - mode = 3 -> 18421555-1
    // - mode = 4 -> devuelve solo el digito verificado.
    // Retorna lo siguiente: string | boolean
    // PERO actualmente solo retorna: string | boolean
    //
    // En fin: retornara string solo cuando el rut sea valido
    // y sera el rut en el formato indicado segun el mode
    // retornara un boolean cuando el rut no sea valido.
    let outRut = this.rutService.getRutChile(0, rut);
    this.rutClean = outRut;

    // Solo recibe el rut como string
    // y lo retorna sin los caracteres espciales
    // let out2_rut = this.rutService.rutClean(rut);
    // console.log('out2_rut: ' + out2_rut);

    // Esta funcion recibe el rut en el formato que sea
    // lo retorna listo con todos los puntos y guiones
    // let out3_rut = this.rutService.rutFormat(rut);
    // console.log('out3_rut: ' + out3_rut);

    // Esta funcion recibe el rut en el formato que sea
    // y retorna un boolean OJO.
    // true cuando el rut NO es valido
    // false cuando es el rut SI es valdo
    // let out4_rut = this.rutService.validaRUT(rut);
    // console.log('out4_rut: ' + out4_rut);
  }

  validarInput(event: KeyboardEvent): boolean {
    // Patrón de expresión regular para permitir solo números, puntos, guiones y la letra 'K' en mayúscula y minúscula
    const pattern = /[0-9.\-kK]/;

    // Obtiene el carácter ingresado
    const inputChar = String.fromCharCode(event.charCode);

    // Comprueba si el carácter ingresado coincide con el patrón
    if (!pattern.test(inputChar)) {
      // Si el carácter ingresado no coincide con el patrón, se cancela su ingreso
      event.preventDefault();
      return false;
    }
    return true;
  }
}