<app-toolbar></app-toolbar>
<div class="container text-center py-4 px-1">
  <div>
    <div class="w-full">
      <h1>MANUAL DE AUTO INSTRUCCIÓN</h1>
      <h3>Buenas Prácticas de Manufactura</h3>
      <br />
      <div class="card w-100 border-danger p-4">
        <p>
          Para el Programa de Alimentación Escolar (PAE) y para el Programa de
          Alimentación Preescolar (PAP).
          <br />
          <br />
          <b>Objetivo:</b>
          <br />
          Al finalizar el curso, el participante será capaz de aplicar
          herramientas de buenas prácticas de manufactura para el Programa de
          Alimentación Escolar (PAE) y el Programa de Alimentación Preescolar
          (PAP), respetando las normas establecidas por JUNAEB.
        </p>
        <img
          src="../../../../assets/images/autoinstruccion/pagina1_imagen1.png"
        />
      </div>
    </div>
    <div class="w-full">
      <h3>El curso consta de 4 módulos:</h3>
      <div class="card w-100 border-danger p-4">
        <p>
          1. NORMATIVA DEL PROGRAMA DE ALIMENTACIÓN ESCOLAR
          <br />
          2. PELIGROS QUE AFECTAN LA SEGURIDAD EN LOS ALIMENTOS
          <br />
          3. BUENAS PRÁCTICAS DE MANUFACTURA
          <br />
          4. TÉCNICAS CULINARIAS
          <br />
          <br />
        </p>
        <h3>En cada módulo encontrarás:</h3>
        <p>
          1. Objetivos de la unidad.
          <br />
          2. Contenidos para estudiar.
          <br />
          3. Actividades.
          <br />
          4. Autoevaluación.
        </p>
        <p>
          Los tiempos de estudio para cada unidad y la entrega de prueba final
          serán entregados por el relator del curso.
        </p>
        <img
          src="../../../../assets/images/autoinstruccion/pagina2_imagen1.jpg"
        />
      </div>
    </div>
    <div class="w-full">
      <h3>CONTENIDOS:</h3>
      <div class="card w-100 border-info p-4">
        Esta sección presenta el desarrollo del contenido del módulo en un
        formato de fácil lectura. Se incluyen ejemplos que ilustran los
        conceptos explicados.
      </div>
      <br />
      <h3>ACTIVIDADES:</h3>
      <div class="card w-100 border-warning p-4">
        Aquí podrás reflexionar sobre los contenidos aprendidos, aprovechando la
        oportunidad para interactuar con tus compañeros de trabajo.
      </div>
      <br />
      <h3>EVALUACIÓN MODULO</h3>
      <div class="card w-100 border-primary p-4">
        Se detallan los instrumentos que te permitirán verificar tu grado de
        aprendizaje en cada unidad del módulo.
      </div>
      <br />
      <h3>EXAMEN FINAL</h3>
      <div class="card w-100 border-success p-4">
        Al concluir el módulo 4, se generará un código que necesitarás para
        realizar el examen final.
      </div>
    </div>
  </div>

  <div class="text-center w-full px-3 py-4">
    <button
      class="btn bg-orange-600 text-white w-full"
      (click)="finModulo()"
      center
    >
      Salir
    </button>
  </div>
</div>
