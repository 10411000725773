import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-borrarinfo',
  templateUrl: './borrarInfo.component.html',
  styleUrls: ['./borrarInfo.component.scss']
})
export class BorrarInfoComponent implements OnInit {
  loginStatus: any;

  constructor(
    private router: Router,
    private loginService: LoginService
  ) {
    this.loginStatus = this.loginService.getLoginStatus();
  }

  ngOnInit(): void {
  }

  loginBTN(): void {
    this.router.navigate(['login']);
  }

  openBTN(): void {
    this.router.navigate(['curso']);
  }

  async borrarInfo(){
    alert('hola');
    const keys = await window.caches.keys();
    await Promise.all(keys.map(key => caches.delete(key)));
  }
}
