<div class="container-pregunta">
  <div
    class="block-preguntas flex flex-col items-center pt-1 bg-white rounded-lg shadow-lg w-full"
  >
    <h1 class="text-2xl font-bold py-4" *ngIf="titulo != ''">{{ titulo }}</h1>
    <div class="text-justify px-4" [innerHTML]="descripcion"></div>
    <form [formGroup]="singleChoiceForm" (ngSubmit)="onSubmit()">
      <div class="w-full p-3">
        <div
          *ngFor="let option of opciones; let i = index"
          class="flex list-group-item text-md"
        >
          <input
            type="radio"
            id="option{{ i }}"
            formControlName="selectedOption"
            [disabled]="isRespondida"
            [value]="option"
            class="pr-2"
          />
          <label class="" for="option{{ i }}"> {{ option }}</label>
        </div>
      </div>
      <div class="w-full px-4 py-6">
        <button
          [disabled]="isRespondida"
          type="submit"
          class="btn bg-orange-500 btn-lg w-full p-2 text-white text-xl font-bold"
        >
          Verificar
        </button>
      </div>
    </form>
  </div>
</div>
