<div class="container-pregunta">
  <div class="block-preguntas flex flex-col items-center pt-1 bg-white rounded-lg shadow-lg w-full">
    <h1 class="text-2xl font-bold py-4">
      ACTIVIDAD 3 ACCIONES CORRECTIVAS RECEPCIÓN CONGELADOS
    </h1>
    <div class="text-justify px-4">
      Para la aceptación o rechazo de las materias primas e insumos que se
      recepcionan, existen criterios descritos en su Manual de Operaciones. En
      la recepción de materias primas, usted recepcionó productos congelados ,
      porque éstos no se encontraban alterados en su textura, color, olor o
      envase, sin embargo a éstos productos les debe aplicar una acción
      correctiva porque la temperatura está fuera del límite crítico. Escriba en
      la celda “acción correctiva” el número de la acción que corresponda hacer.
    </div>

    <div class="grid grid-cols-4 px-5 text-center pt-3">
      <div class="border-2"></div>
      <div class="border-2">Producto</div>
      <div class="border-2">T° Recepción</div>
      <div class="border-2">Acción Correctiva</div>
      <div class="border-2">A)</div>
      <div class="border-2">Carne congelada</div>
      <div class="border-2">-13,0 °C</div>
      <div class="border-2">
        <mat-form-field appearance="fill" class="w-full text-center">
          <mat-label>Seleccionar Acción correctiva</mat-label>
          <mat-select
            (selectionChange)="onOptionSelected($event, 0)"
            [value]="selectOpc0"
            [disabled]="isRespondida"
          >
            <mat-option *ngFor="let accion of accCorrectiva" [value]="accion">
              {{ accion }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="border-2">B)</div>
      <div class="border-2">Filetillo pollo congelada</div>
      <div class="border-2">-10,0 °C</div>
      <div class="border-2">
        <mat-form-field appearance="fill" class="w-full text-center">
          <mat-label>Seleccionar Acción correctiva</mat-label>
          <mat-select
            (selectionChange)="onOptionSelected($event, 1)"
            [value]="selectOpc1"
            [disabled]="isRespondida"
          >
            <mat-option *ngFor="let accion of accCorrectiva" [value]="accion">
              {{ accion }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="w-100 px-4 py-6">
      <button
        [disabled]="isRespondida"
        (click)="validarPregunta()"
        class="btn bg-orange-500 btn-lg w-100 p-2 text-white text-xl font-bold"
      >
        Verificar
      </button>
    </div>
  </div>
</div>
