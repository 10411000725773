import { Injectable } from '@angular/core';
import * as CryptoJS from "crypto-js";
import { trim } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class CryptoservService {

  constructor() { }

  key = "hola";
  iv = CryptoJS.enc.Utf8.parse("7061737323313233");

  public encrypted(text) {
    text = text.toString();
    return CryptoJS.AES.encrypt(text.trim(), this.key.trim()).toString();
  }

  public decrypted(textCrypto): string {
    return CryptoJS.AES.decrypt(textCrypto, this.key).toString(CryptoJS.enc.Utf8);
  }

}
