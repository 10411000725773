import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-personalizado-question",
  templateUrl: "./personalizadoQuestion.component.html",
  styleUrls: ["./personalizadoQuestion.component.scss"],
})
export class PersonalizadoQuestionComponent implements OnInit{
  @Input() isRespondida: boolean;
  @Output() sendRespuesta: EventEmitter<any> = new EventEmitter<any>();

  accCorrectiva = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  selectOpc0 = 0;
  selectOpc1 = 0;

  alternativeChoiceForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.alternativeChoiceForm = this.fb.group({
      options: this.fb.array([]),
      selectedChoice: new FormControl('')
    });
  }

  ngOnInit(): void {
   // this.addCheckboxes();
    if(this.isRespondida){
      this.selectOpc0 = 3;
      this.selectOpc1 = 4;
    }
  }


  onOptionSelected(event, pos){
    console.log("hola");
    console.log(event);
    console.log(pos);
    if(pos == 0){
      this.selectOpc0 = event.value;
    }
    if(pos == 1){
      this.selectOpc1 = event.value;
    }
  }
  // private addCheckboxes() {
  //   this.opciones.forEach(() => this.alternativeChoiceForm.controls.options.push(new FormControl(false)));
  // }

  validarPregunta(){
    console.log(this.selectOpc0);
    console.log(this.selectOpc1);
    if(this.selectOpc0 == 3 && this.selectOpc1 == 4){
      this.sendRespuesta.emit(true);
    } else {
      this.sendRespuesta.emit(false);
    }
  }
}
