<app-toolbar></app-toolbar>
<div class="container">
  <div class="pdf-container"  *ngIf="pdfActive[0]">
    <pdf-viewer
      [src]="Pdf1"
      [render-text]="true"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="onPageRendered($event)"
      [show-all]="false"
      [page]="page[0]"
      [original-size]="false"
    ></pdf-viewer>
  </div>
  <div class="img-tabla"  *ngIf="imgActive[0]">
    <img class="w-full h-full" [src]="img1">
  </div>
  <div class="pdf-container"  *ngIf="pdfActive[1]">
    <pdf-viewer
      [src]="Pdf2"
      [render-text]="true"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="onPageRendered($event)"
      [show-all]="false"
      [page]="page[1]"
      [original-size]="false"
    ></pdf-viewer>
  </div>


  <div *ngIf="preguntaActive[0]">
    <app-customer-question
      [titulo]="titulo1"
      [descripcion]="descripcion1"
      [inputHtml]="inputHtml1"
      [opciones]="opciones1"
      [correctAnswer]="correcta1"
      [isRespondida]="validRespondida(1)"
      (sendRespuesta)="validarQuestion($event,1)"
    ></app-customer-question>
  </div>
  
  <div class="pdf-container" *ngIf="pdfActive[2]">
    <pdf-viewer
      [src]="Pdf3"
      [render-text]="true"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="onPageRendered($event)"
      [show-all]="false"
      [page]="page[2]"
      [original-size]="false"
    ></pdf-viewer>
  </div>

  <div class="img-tabla"  *ngIf="imgActive[1]">
    <img class="w-full h-full" [src]="img2">
  </div>

  <div class="pdf-container" *ngIf="pdfActive[3]">
    <pdf-viewer
      [src]="Pdf4"
      [render-text]="true"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="onPageRendered($event)"
      [show-all]="false"
      [page]="page[3]"
      [original-size]="false"
    ></pdf-viewer>
  </div>

  <div class="pdf-container" *ngIf="pdfActive[4]">
    <pdf-viewer
      [src]="Pdf5"
      [render-text]="true"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="onPageRendered($event)"
      [show-all]="false"
      [page]="page[4]"
      [original-size]="false"
    ></pdf-viewer>
  </div>

  <div class="img-tabla"  *ngIf="imgActive[2]">
    <img class="w-full h-full" [src]="img3">
  </div>

  <div class="pdf-container" *ngIf="pdfActive[5]">
    <pdf-viewer
      [src]="Pdf6"
      [render-text]="true"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="onPageRendered($event)"
      [show-all]="false"
      [page]="page[5]"
      [original-size]="false"
    ></pdf-viewer>
  </div>

  <div *ngIf="preguntaActive[1]">
    <app-alternative-question
    [titulo]="titulo2"
      [descripcion]="descripcion2"
      [opciones]="opciones2"
      [correctAnswer]="correcta2"
      [choices]="choices2"
      [isRespondida]="validRespondida(2)"
      (sendRespuesta)="validarQuestion($event,2)"
    ></app-alternative-question>

  </div>

  <div class="pdf-container" *ngIf="pdfActive[6]">
    <pdf-viewer
      [src]="Pdf7"
      [render-text]="true"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="onPageRendered($event)"
      [show-all]="false"
      [page]="page[6]"
      [original-size]="false"
    ></pdf-viewer>
  </div>

  <div class="img-tabla" *ngIf="imgActive[3]">
    <img class="w-full h-full" [src]="img4">
  </div>

  <div class="pdf-container" *ngIf="pdfActive[7]">
    <pdf-viewer
      [src]="Pdf8"
      [render-text]="true"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="onPageRendered($event)"
      [show-all]="false"
      [page]="page[7]"
      [original-size]="false"
    ></pdf-viewer>
  </div>

  <div class="pdf-container" *ngIf="pdfActive[8]">
    <pdf-viewer
      [src]="Pdf9"
      [render-text]="true"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="onPageRendered($event)"
      [show-all]="false"
      [page]="page[8]"
      [original-size]="false"
    ></pdf-viewer>
  </div>

  <div class="img-tabla"  *ngIf="imgActive[4]">
    <img class="w-full h-full" [src]="img5">
  </div>

  <div class="pdf-container" *ngIf="pdfActive[9]">
    <pdf-viewer
      [src]="Pdf10"
      [render-text]="true"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="onPageRendered($event)"
      [show-all]="false"
      [page]="page[9]"
      [original-size]="false"
    ></pdf-viewer>
  </div>

  <div class="img-tabla"  *ngIf="imgActive[5]">
    <img class="w-full h-full" [src]="img6">
  </div>


  <div class="pdf-container" *ngIf="pdfActive[10]">
    <pdf-viewer
      [src]="Pdf11"
      [render-text]="true"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="onPageRendered($event)"
      [show-all]="false"
      [page]="page[10]"
      [original-size]="false"
    ></pdf-viewer>
  </div>

  <div *ngIf="preguntaActive[2]">
    <app-true-false-question
      [titulo]="titulo4"
      [descripcion]="descripcion4"
      [questions]="preguntas4"
      [respuestas]="respuestas4"
      [isRespondida]="validRespondida(4)"
      (sendRespuesta)="validarQuestion($event,4)"
    ></app-true-false-question>
  </div>

  <div class="p-4 justify-center item-center" *ngIf="pdfActive[10]">
    <h1>🎉 ¡FELICIDADES HAZ FINALIZADO EL MODULO! 🎉</h1>
    <img class="w-full p-4" src="../../../../assets/images/fin-modulo.jpg" />
    <div class="w-100 px-4 py-6">
      <button
        class="btn bg-orange-500 btn-lg w-100 p-2 text-white text-lg font-bold"
        (click)="finModulo()"
      >
        Salir
      </button>
    </div>
  </div>

</div>
<app-paginacion
  [page]="sumaPage"
  [totalPages]="totalPages"
  [cantPreg]="cantPreg"
  (prevPage)="prevPage()"
  (nextPage)="nextPage()"
></app-paginacion>
