<div class="container pt-100 d-flex flex-column justify-content-center align-items-center">
    <br><br><br>
    <img src="../../../assets/images/logoAlicopsa.png">
    <h4 >Panel administrador</h4>
    <h1 > --------------------- </h1>
    <div class="container p-4 colorGris d-flex flex-column justify-content-center align-items-center">
        <h2 >admin</h2>
        <hr>
        <input type="file" class="form-control" (change)="addfile($event)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">        
        <hr>
        Registros obtenidos: <span class="colorGris">{{cantidadLeido}}</span>
        <hr>
        <button mat-raised-button color="primary" (click)="cargarUsuarios()">Cargar Usuarios</button>
    </div>
<br>
</div>